import { FormFields } from "@/components/ContactForm";
import { pushToDataLayer } from "@/utils/analytics/pushToDataLayer";

type FormProperties = { formName: string; formVersion: number };
export const formAbandonmentEvent = (
  formValues: FormFields,
  { formName, formVersion }: FormProperties
) =>{
  //console.log("Form values:", formValues);

  pushToDataLayer("form_abandonment", {
    form_name: formName,
    form_version: formVersion.toString(),
    form_fields_dirtied: Object.keys(formValues).reduce(
      (acc, key) => {
        const value = formValues[key as keyof FormFields];
        return {
          ...acc,
          [key]: Boolean(value !== "" && value !== 0)  
        };
      },
      {} as Record<keyof FormFields, boolean>
    ),
  });}

export const formSubmissionEvent = (formProperties: FormProperties) =>
  pushToDataLayer("form_submission", {
    form_name: formProperties.formName,
    form_version: formProperties.formVersion.toString(),
  });
